import 'whatwg-fetch';
import { h, render, Fragment } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import {
    TextField,
    SelectField,
    StandardForm,
    useForm,
    get_data,
    v,
    f,
    opts,
    json_to_urlencoded,
} from '@xmlleads/form-magic';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const Forgot = ({ set_show_forgot_password }) => {
    const [forgot_form, update_forgot_form] = useForm({});
    const { executeRecaptcha } = useGoogleReCaptcha();
    const forgot_schema = {
        email: v.required,
    };

    const get_csrf = async () => {
        const response = await fetch('/Web/form/get-csrf');
        if (!response.ok) {
            throw 'Non-200 on CSRF fetch';
        }
        const data = await response.json();
        return data;
    };

    const handle_recaptcha = async (action) => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const token = await executeRecaptcha(action);
        return token;
    };

    const forgot_submit = async () => {
        const csrf = await get_csrf();
        const data = get_data(forgot_form);
        data[csrf.token_name] = csrf.token_value;
        const captcha = await handle_recaptcha('forgot');
        data['captcha'] = captcha;
        const res = await fetch('/Web/login/forgot/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: json_to_urlencoded(data),
        });
        const response = await res.json();
        alert('You should get an email with further instructions.');
    };

    return (
        <Fragment>
            <h1 className="mt-6 text-3xl font-extrabold text-gray-900">
                Recover Your Account
            </h1>
            <div className="mt-8">
                <div className="mt-6">
                    <StandardForm
                        className="space-y-6"
                        schema={forgot_schema}
                        form={forgot_form}
                        update={update_forgot_form}
                        id="login-form"
                        submit={forgot_submit}
                        show_application_error={() => {}}
                    >
                        <TextField label="Email:" name="email" />
                    </StandardForm>
                </div>
            </div>
        </Fragment>
    );
};

export default Forgot;
