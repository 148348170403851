import 'whatwg-fetch';
import { h, render, Fragment } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import {
    TextField,
    SelectField,
    StandardForm,
    useForm,
    get_data,
    set_errors,
    v,
    f,
    opts,
    json_to_urlencoded,
    always_allowed_keycodes,
} from '@xmlleads/form-magic';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Modal from 'react-modal';

Modal.setAppElement('#login-container');

const Login = ({ set_show_forgot_password, redirect }) => {
    const [form, update_form] = useForm({});
    const [processing, set_processing] = useState(false);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const schema = {
        email: v.required,
        password: v.required,
    };

    const get_csrf = async () => {
        const response = await fetch('/Web/form/get-csrf');
        if (!response.ok) {
            throw 'Non-200 on CSRF fetch';
        }
        const data = await response.json();
        return data;
    };

    const handle_recaptcha = async (action) => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const token = await executeRecaptcha(action);
        return token;
    };

    const submit = async () => {
        set_processing(true);
        const data = get_data(form);
        const errors = {};
        const key = [];
        Object.keys(schema).map((i) => {
            const result = schema[i](data[i]);
            if (result) {
                key.push(i);
                errors[i] = result;
            }
        });
        if (key.length > 0) {
            update_form({ key, error: errors, type: 'show_error' });
            set_processing(false);
            return;
        }
        const csrf = await get_csrf();
        data[csrf.token_name] = csrf.token_value;
        const captcha = await handle_recaptcha('login');
        data['captcha'] = captcha;
        const res = await fetch('/Web/login/login/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: json_to_urlencoded(data),
        });
        const response = await res.json();
        if (response.success) {
            if (redirect) {
                window.location = redirect;
            } else if (response.update_password) {
                window.location = '/Web/updatepassword/';
            } else if (response.offer) {
                window.location = '/Web/offer/';
            } else if (response.ibv_redirect) {
                window.location = '/Web/bank-verification/';
            } else if (response.agreement_redirect) {
                window.location = `/Web/account/sign-loan/${response.loan_id}`;
            } else {
                window.location = '/Web/account/';
            }
        } else {
            set_errors(update_form, response.errors);
            if (response.errors['main-error']) {
                alert(response.errors['main-error']);
            }
        }
        set_processing(false);
    };

    const prevent_non_password = (e) => {
        let key;
        if (e.key) {
            key = e.key;
        } else {
            const code = e.which || e.keyCode;
            key = String.fromCharCode(code);
        }
        if (key && !key.replace(/[\^\'\"\<\>\,\s]/gm, '').length) {
            if (!always_allowed_keycodes(e.code || key)) {
                e.preventDefault();
            }
        }
    };

    const child_modal_style = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '400px',
        },
    };

    return (
        <Fragment>
            <Modal isOpen={processing} style={child_modal_style}>
                <h1>Processing, please wait...</h1>
            </Modal>
            <h1 className="mt-6 text-3xl font-extrabold text-gray-900">
                Sign in to your account
            </h1>
            <div className="mt-8">
                <div className="mt-6">
                    <StandardForm
                        className="space-y-6"
                        schema={schema}
                        form={form}
                        update={update_form}
                        id="login-form"
                        submit={submit}
                        show_application_error={() => {}}
                    >
                        <TextField label="Email:" name="email" />
                        <TextField
                            label="Password:"
                            name="password"
                            type="password"
                        />
                        {set_show_forgot_password && (
                            <Fragment>
                                <br />
                                <a
                                    href="javascript:void()"
                                    onClick={() => {
                                        set_show_forgot_password(true);
                                    }}
                                >
                                    Forgot Password?
                                </a>
                            </Fragment>
                        )}
                    </StandardForm>
                </div>
            </div>
        </Fragment>
    );
};

export default Login;
